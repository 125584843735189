const placeMatrix = [
    {
        id: '1',
        color: 'indigo',
        group: 'Employees',
        buttons: [
            { id: 'Users\\Entity\\User\\Employee', color: 'indigo-5', title: 'Employee' },
        ]
    },
    {
        id: '2',
        color: 'cyan',
        group: 'Customer portals',
        buttons: [
            { id: 'Users\\Entity\\User', color: 'cyan-5', title: 'User' }
        ]
    }
]

export default placeMatrix

/**
 * Find state option
 *
 * @param {string|number} id
 *
 * @returns {object|null}
 */
export function getStoragePlaceState(id) {
    for (let i = 0; i < placeMatrix.length; i++) {
        const option = placeMatrix[i].buttons.find(x => x.id === id)

        if (option) {
            return option
        }
    }

    return null
}
