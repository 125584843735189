<template>
  <div
    :style="`width: ${width}`"
    class="border-right"
  >
    <q-scroll-area :style="`height: 100%; max-width: ${width};`">
      <q-list
        padding
        class="q-pt-none"
      >
        <q-item
          v-ripple
          class="q-pl-none relative"
          style="min-height: 47px;"
        >
          <q-item-section
            avatar
            class="items-center"
          >
            <q-btn
              :icon="isExpanded ? 'chevron_left' : 'chevron_right'"
              size="sm"
              color="dark"
              round
              @click="handleExpandCollapse"
            />
          </q-item-section>

          <q-item-section v-if="isExpanded">
            <strong>{{ $t(title) }}</strong>
          </q-item-section>
        </q-item>

        <div
          v-for="group in statuses"
          :key="group.id"
        >
          <q-separator />

          <q-item
            v-ripple
            clickable
            class="q-px-none"
            @click="handleClickGroup(group)"
          >
            <q-item-section
              avatar
              class="items-center"
            >
              <span
                :class="`bg-${group.color} rounded`"
                style="width: 10px; height: 10px;"
              />
            </q-item-section>

            <q-item-section
              v-if="isExpanded"
              class="text-caption full-width"
            >
              <strong>{{ $t(group.group) }}</strong>
            </q-item-section>
          </q-item>

          <q-separator />

          <q-item
            v-for="state in group.buttons"
            :key="state.id"
            v-ripple
            clickable
            class="q-px-none"
            active-class="bg-secondary text-white"
            :active="outsideSelectedStatus.includes(state.id)"
            @click="handleClick(state)"
          >
            <q-item-section
              avatar
              class="items-center"
            >
              <div
                :class="`bg-${state.color} rounded`"
                style="width: 10px; height: 10px;"
              />
            </q-item-section>

            <q-item-section v-if="isExpanded">
              {{ $t(state.title) }}
            </q-item-section>

            <q-item-section
              v-if="isExpanded && outsideSelectedStatus.includes(state.id)"
              class="block text-right q-my-sm q-mr-md"
            >
              <q-icon name="check" color="dark" />
            </q-item-section>
          </q-item>
        </div>
      </q-list>
    </q-scroll-area>
  </div>
</template>

<script>
import orderStatusMatrix from './../../config/OrderMatrix'

export default {
  name: 'StatusFilter',
  emits: ['on-change'],
  props: {
    title: {
      type: String,
      default () {
        return 'Statuses'
      }
    },
    isOpen: {
      type: Boolean,
      default () {
        return false
      }
    },
    outsideSelectedStatus: {
      type: Array,
      default () {
        return []
      }
    },
    statuses: {
      type: Array,
      default () {
        return orderStatusMatrix
      }
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    width () {
      return this.isExpanded
        ? '200px'
        : '42px'
    }
  },
  watch: {
    isOpen (value) {
      this.isExpanded = value
    }
  },
  methods: {
    handleExpandCollapse () {
      this.isExpanded = !this.isExpanded
    },
    handleClickGroup (group) {
      const states = group.buttons.map(({ id }) => id)
      const shouldResetStates = this.outsideSelectedStatus.find(val => states.includes(val))

      if (shouldResetStates) {
        this.$emit('on-change', this.outsideSelectedStatus.filter(val => !states.includes(val)))
      } else {
        this.$emit('on-change', [...states, ...this.outsideSelectedStatus])
      }
    },
    handleClick (status) {
      const { id } = status

      if (this.outsideSelectedStatus.includes(id)) {
        this.$emit('on-change', this.outsideSelectedStatus.filter(val => val !== id))
      } else {
        this.$emit('on-change', [id, ...this.outsideSelectedStatus])
      }
    }
  }
}
</script>
